import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import { artworkRouteConfig } from "features/Artwork/artwork.routes";
import SubheaderBase from "features/Header/SubheaderBase/SubheaderBase";
import NavigationLink from "features/Navigation/components/NavigationLink";
import useSearch from "features/Search/hooks/useSearch";
import { viewingRoomRoutes } from "features/ViewingRoom/viewing-room.routes";
import React from "react";
import { useCallback } from "react";
import { customProps, secondaryFont, zIndex } from "styles";
import { down, up } from "styles/utils/breakpoints";
import em from "styles/utils/em";
import getFullName from "utils/getFullName";
import useShallowSearch from "../../features/Search/hooks/useShallowSearch";

const Root = styled.div`
  position: absolute;
  width: 100%;
  z-index: ${zIndex.header - 5};
  left: 0;
  right: 0;
`;

const Content = styled.div`
  padding: 20px;
  padding-top: 0;

  ${up("md")} {
    display: flex;
    justify-content: center;
    background: #ffffff;
    padding: 20px;
  }

  ${up("lg")} {
    padding-left: var(${customProps.layoutPl});
    padding-right: var(${customProps.layoutPr});
  }
`;

const ResultItems = styled.div`
  ${down("md")} {
    margin-top: 19px;
  }
  ${up("md")} {
    padding-right: 30px;
    width: 33.33%;
    & + & {
      padding-left: 30px;
      border-left: 1px solid #979797;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`;

const NotFound = styled.span`
  font-size: 14px;
  display: none;
  text-transform: uppercase;
  ${up("md")} {
    display: initial;
  }
`;

const ResultsType = styled.span`
  font-family: ${secondaryFont};
  font-weight: 400;
  font-size: 1.1em;

  ${up("md")} {
    font-style: italic;
  }
`;

const resultLinkCss = css`
  display: block;
  color: #000000;
  text-align: left;

  font-size: 14px;
  text-decoration: none;
  line-height: ${em("19px", "14px")};
  letter-spacing: ${em("0.78px", "14px")};

  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

  & + & {
    margin-top: 10px;
  }
`;

const ItemName = styled.span`
  font-weight: 500;
  text-transform: uppercase;
`;

const Subheader = styled(SubheaderBase)`
  display: flex;
  justify-content: center;
`;

const NothingFound = styled.div`
  text-transform: uppercase;
  text-align: center;
  color: #000000;
  font-size: 14px;
`;

interface SearchOverlayProps {
  className?: string;
}

const SearchOverlay = ({ className }: SearchOverlayProps) => {
  const {
    searchQuery: { isSuccess, isLoading, data },
    closeSearch,
    deepSearch,
  } = useShallowSearch();
  const { handleResetFilters } = useSearch()
  const hasResult =
    data?.artworks.length || data?.viewingRooms.length || data?.artists.length;

  const searchArtist = useCallback((item) => {
    handleResetFilters()
    deepSearch({ artists: item })
  }, [deepSearch, handleResetFilters])

  return (
    <Root className={className}>
      {!isLoading && isSuccess && !hasResult && (
        <Subheader>
          <NothingFound>Nothing found</NothingFound>
        </Subheader>
      )}

      {!isLoading && hasResult ? (
        <Content>
          <ResultItems>
            {!!data?.artworks.length ? (
              data?.artworks.map((item) => {
                return (
                  <NavigationLink
                    key={item.id}
                    addGoBackState
                    className={resultLinkCss}
                    to={artworkRouteConfig.link(item.viewingRoomId, item.id)}
                    onClick={closeSearch}
                  >
                    <ItemName>{item.name}</ItemName>
                    <br />
                    <ResultsType>Artwork</ResultsType>
                  </NavigationLink>
                );
              })
            ) : (
              <NotFound>Artworks not found</NotFound>
            )}
          </ResultItems>
          <ResultItems>
            {!!data?.viewingRooms.length ? (
              data?.viewingRooms.map((item) => {
                return (
                  <NavigationLink
                    key={item.id}
                    addGoBackState
                    className={resultLinkCss}
                    to={viewingRoomRoutes.link(item.id)}
                    onClick={closeSearch}
                  >
                    <ItemName>{item.name}</ItemName>
                    <br />
                    <ResultsType>Viewing Room</ResultsType>
                  </NavigationLink>
                );
              })
            ) : (
              <NotFound>Viewing rooms not found</NotFound>
            )}
          </ResultItems>
          <ResultItems>
            {!!data?.artists.length ? (
              data?.artists.map((item) => {
                return (
                  <button
                    key={item.id}
                    className={resultLinkCss}
                    onClick={() => searchArtist(item)}
                  >
                    <ItemName>{getFullName(item)}</ItemName>
                    <br />
                    <ResultsType>Artist</ResultsType>
                  </button>
                );
              })
            ) : (
              <NotFound>Artists not found</NotFound>
            )}
          </ResultItems>
        </Content>
      ) : null}
    </Root>
  );
};

export default SearchOverlay;
