export const frieze = "https://frieze.com/";
export const friezeVetting = "https://www.frieze.com/page/vetting-statement";
export const friezeLegals = "https://frieze.com/legals";
export const friezeCookiePolicy = "https://frieze.com/cookie";
export const friezePrivacyPolicy = "https://frieze.com/privacy";
export const friezeFairsLink = "https://frieze.com/fairs";

export const signUpQueryConfig = {
  theme: "fvr",
};
export const vipSignUpLink =
  "https://www.frieze.com/account/register-vip-password";
export const publicSignUpLink = "https://www.frieze.com/account/register";
