import { styled } from "@linaria/react";
import RatioWrapperBase, {
  ratio,
} from "components/RatioWrapper/RatioWrapperBase";
import { cover } from "polished";
import fluid from "styles/utils/fluid";

export const NewsImageWrapper = styled(RatioWrapperBase)`
  ${ratio(82 / 110)}
  ${fluid("width", {
    base: "62px",
    md: "62px",
    lg: "82px",
    xlg: "126px",
  })}
  flex-shrink: 0;

  &.full {
    ${ratio(179 / 127)}
    width: 179px;
  }
`;

const NewsImage = styled.img`
  ${cover()}
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
`;

export default NewsImage;
