import * as React from "react";

const SvgFriezeHeaderLogoMobile = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 90 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 .75v2.752H3.278v6.035h7.25v2.571h-7.25v7.642H0v-19h11zm7.095 2.71V10h3.002c1.058 0 1.862-.277 2.413-.832.551-.554.827-1.366.827-2.438 0-1.07-.276-1.883-.827-2.437-.55-.554-1.355-.832-2.413-.832h-3.002zm6.44 16.29l-3.028-7.07h-3.412v7.07H15v-19h6.348c1.984 0 3.496.512 4.536 1.537 1.04 1.024 1.56 2.52 1.56 4.484 0 1.342-.262 2.452-.786 3.332-.525.88-1.285 1.521-2.282 1.921L28 19.75h-3.465zm17.465 0H32v-2.682h3.464V3.432H32V.75h10v2.682h-3.45v13.636H42v2.682zm9.19-8.061v5.323H59v2.738H48v-19h10.919v2.738h-7.73v5.644h7.27v2.557h-7.27zm15.991 5.197a.36.36 0 00.27.112h7.143v2.752H62l8.184-16.262h-7.467V.75H75l-7.832 15.521a.89.89 0 00-.095.35c0 .102.036.19.108.265zm15.009-5.197v5.323H90v2.738H79v-19h10.919v2.738h-7.73v5.644h7.27v2.557h-7.27z"
      fill="#1A1919"
    />
  </svg>
);

export default SvgFriezeHeaderLogoMobile;
