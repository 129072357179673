import React, { useMemo } from "react";
import clsx from "clsx";
import { styled } from "@linaria/react";
import { ContextNavigationArrow } from "components/icons";
import Svg from "components/Svg";
import SubheaderBase from "features/Header/SubheaderBase/SubheaderBase";
import NavigationLink, { NavigationLinkProps } from "features/Navigation/components/NavigationLink";
import useDisableLinkHandler from "hooks/useDisableLinkHandler";
import { colors } from "styles";
import { up } from "styles/utils/breakpoints";
import { ContextNavigationOptions } from "../context-navigation.interfaces";
import clearMetaText from "../utils/clearMetaText";

interface NavLink {
  name?: string;
  link?: NavigationLinkProps['to']
}

type DesktopContextNavigationTypes = "section" | "galleries" | "artworks" | "account" | "fair";

interface ContextNavigationProps extends ContextNavigationOptions {
  className?: string;
  type?: DesktopContextNavigationTypes;
  meta?: Array<NavLink>
}

const Root = styled(SubheaderBase)`
  position: fixed;
  left: 0;
  top: var(--header-height);
  width: 100%;
  z-index: 90;
  justify-content: space-between;
  padding: 0 18px;

  ${up("lg")} {
    padding: 0 30px;
  }
  .dark & {
    background-color: ${colors.dark};
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const MetaArrow = styled(Svg)`
  font-size: 8px;
  margin-left: 5px;
  margin-right: 5px;
`

const NavMetaText = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-family: FriezeSans;
  letter-spacing: 0.35px;
  text-transform: uppercase;
`

const NavMetaItem = styled(NavigationLink)`
  font-size: 12px;
  font-weight: normal;
  font-family: FriezeSans;
  letter-spacing: 0.35px;
  text-transform: uppercase;
  display: flex;
`

const NavSection = styled.div`
  display: flex;
  align-items: center;

  & ${NavMetaItem}:last-child {
    font-weight: 500
  }
`

const NavActionButton = styled(NavigationLink)`
  display: flex;
  cursor: pointer;
  &.disabled {
    opacity: 0.5;
  }
`

const NavLinkGroup = styled.div`
  display: flex;
  margin-left: 8px;
`

const VSpacer = styled(NavMetaText)`
  margin-left: 10px;
  margin-right: 10px;
`

const NavigationPlaceholder = styled(SubheaderBase)`
  position: relative;
`

export const ContextNavigationDesktop = ({
  prevLink,
  nextLink,
  className,
  meta = [],
  type
}: ContextNavigationProps) => {
  const disabledHandler = useDisableLinkHandler();

  const metaTitles = useMemo(() => {
    return meta.length ? meta.filter(i => (!!i.name && !!i.link)) : []
  }, [meta])

  return (
    <>
      <Root className={className}>
        <InnerWrapper>
          <NavSection>
            { metaTitles.map((item, index) => (
              <React.Fragment key={index}>  
                { index !== 0 &&
                  <MetaArrow> 
                    <ContextNavigationArrow width="10" height="10" />
                  </MetaArrow>
                }
                { item.name && item.link && <NavMetaItem to={item.link}>{clearMetaText(item.name)}</NavMetaItem> }
              </React.Fragment>
            ))}
          </NavSection>


          <NavSection>
            <NavMetaText>Browse {type}:</NavMetaText>
            <NavLinkGroup>
              <NavActionButton onClick={!prevLink ? disabledHandler : undefined} replace to={prevLink ?? ""} className={clsx({ disabled: !prevLink })}>
                <NavMetaText>PREV</NavMetaText>
              </NavActionButton>
              <VSpacer>|</VSpacer>
              <NavActionButton onClick={!nextLink ? disabledHandler : undefined} replace to={nextLink ?? ""} className={clsx({ disabled: !nextLink })}>
                <NavMetaText>NEXT</NavMetaText>
              </NavActionButton>
            </NavLinkGroup>
          </NavSection>
        </InnerWrapper>
      </Root>
      <NavigationPlaceholder />
    </>
  );
};
