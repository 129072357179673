import clsx from "clsx";
import React from "react";
import classes from "./Divider.module.scss";

type DividerProps = {
  className?: string;
  vertical?: boolean;
  color?: string;
};

export const Divider = ({ className, vertical, color }: DividerProps) => {
  return (
    <div
      style={color ? { backgroundColor: color } : undefined}
      className={clsx(classes.root, className, {
        [classes.rootVertical]: vertical,
      })}
    />
  );
};
