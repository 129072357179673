import * as React from "react";

const SvgDeutscheBank = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    className="filled"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2854 690"
    {...props}
  >
    <g transform="translate(0.000000,690.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path d="M538 6890 c-308 -49 -482 -255 -527 -625 -29 -236 3 -483 85 -654 48 -100 151 -205 244 -249 123 -58 176 -67 380 -66 167 0 195 3 285 27 55 15 138 46 185 69 l85 43 3 338 2 337 -295 0 -295 0 0 -75 0 -75 205 0 205 0 0 -213 0 -214 -47 -22 c-83 -38 -154 -52 -294 -58 -155 -7 -213 3 -302 50 -122 64 -200 191 -233 378 -23 130 -15 400 15 512 65 246 225 360 481 344 153 -10 246 -63 296 -171 13 -28 27 -72 30 -98 l7 -48 102 0 102 0 -9 49 c-39 218 -159 352 -363 406 -72 19 -267 28 -347 15z"/>
      <path d="M1682 6093 l3 -778 90 0 90 0 3 778 2 777 -95 0 -95 0 2 -777z"/>
      <path d="M3550 6090 l0 -780 84 0 84 0 6 31 c3 17 6 47 6 67 l0 35 53 -50 c153 -145 458 -133 615 24 101 101 146 240 146 448 0 201 -37 320 -130 424 -83 93 -208 136 -369 129 -79 -3 -102 -9 -157 -36 -39 -19 -84 -52 -112 -81 l-46 -50 0 309 0 310 -90 0 -90 0 0 -780z m606 165 c130 -38 203 -178 204 -385 0 -282 -104 -422 -315 -424 -68 -1 -88 3 -137 27 -106 52 -162 144 -180 297 -14 118 1 240 37 313 43 85 119 155 195 178 42 13 144 10 196 -6z"/>
      <path d="M6100 6090 l0 -781 88 3 87 3 3 778 2 777 -90 0 -90 0 0 -780z"/>
      <path d="M7180 6090 l0 -781 88 3 87 3 3 778 2 777 -90 0 -90 0 0 -780z"/>
      <path d="M10960 6572 l0 -299 -42 40 c-23 22 -71 54 -107 71 -115 53 -292 49 -419 -11 -158 -74 -252 -270 -252 -525 1 -309 140 -508 384 -549 167 -28 333 25 410 130 l25 34 3 -74 3 -74 85 0 85 0 3 778 2 777 -90 0 -90 0 0 -298z m-213 -317 c71 -21 142 -87 179 -167 28 -62 29 -67 29 -228 0 -161 -1 -167 -29 -227 -31 -68 -104 -145 -159 -168 -92 -38 -225 -25 -305 29 -97 67 -139 196 -130 402 8 168 45 264 129 326 63 48 189 62 286 33z"/>
      <path d="M21670 3440 l0 -3430 3435 0 3435 0 0 3430 0 3430 -3435 0 -3435 0 0 -3430z m5910 5 l0 -2475 -2475 0 -2475 0 0 2475 0 2475 2475 0 2475 0 0 -2475z"/>
      <path d="M24495 3448 l-1336 -1793 603 -3 c564 -2 603 -1 619 15 16 18 2647 3536 2663 3561 6 9 -117 12 -603 12 l-609 0 -1337 -1792z"/>
      <path d="M15580 6550 l0 -150 -105 0 -106 0 3 -72 3 -73 103 -3 102 -3 0 -372 c0 -218 4 -387 10 -408 16 -58 51 -102 100 -126 61 -30 182 -47 275 -38 l75 7 0 71 0 72 -103 0 c-113 0 -149 13 -167 61 -6 14 -10 179 -10 380 l0 354 135 0 135 0 0 75 0 75 -135 0 -135 0 0 150 0 150 -90 0 -90 0 0 -150z"/>
      <path d="M2544 6402 c-166 -50 -279 -179 -319 -368 -19 -89 -19 -269 0 -358 39 -185 148 -311 312 -361 26 -8 86 -18 133 -21 212 -15 394 77 472 238 82 167 90 422 19 608 -43 112 -143 211 -256 251 -85 30 -275 36 -361 11z m312 -160 c59 -30 110 -94 136 -171 20 -57 23 -86 23 -211 0 -165 -13 -225 -67 -306 -24 -36 -49 -57 -92 -79 -54 -27 -69 -30 -150 -30 -79 1 -98 4 -142 28 -63 32 -107 83 -136 157 -20 49 -22 74 -23 225 0 156 2 175 24 229 30 74 82 132 146 163 44 21 61 24 145 20 73 -2 105 -8 136 -25z"/>
      <path d="M5145 6407 c-140 -36 -220 -112 -259 -244 l-15 -53 89 0 88 0 12 36 c19 57 65 102 123 119 104 31 236 13 291 -38 41 -39 56 -88 56 -183 l0 -71 -192 -6 c-144 -4 -210 -11 -259 -25 -134 -38 -205 -101 -239 -211 -53 -170 3 -327 143 -397 147 -75 388 -46 505 61 l41 36 3 -58 3 -58 85 0 85 0 0 420 c0 490 0 492 -89 581 -76 75 -138 96 -296 100 -80 2 -143 -1 -175 -9z m383 -680 c-3 -89 -7 -108 -30 -148 -55 -93 -148 -139 -284 -139 -95 0 -141 15 -174 58 -46 58 -49 171 -7 233 54 80 115 97 336 98 l163 1 -4 -103z"/>
      <path d="M8041 6404 c-231 -62 -352 -273 -338 -593 8 -202 50 -314 149 -406 91 -84 231 -124 392 -111 191 16 311 95 366 242 31 85 32 84 -64 84 l-83 0 -17 -45 c-36 -94 -136 -142 -281 -133 -97 7 -147 29 -198 89 -44 50 -66 112 -74 201 l-6 68 379 2 379 3 3 83 c10 286 -108 475 -326 521 -73 15 -216 13 -281 -5z m289 -159 c80 -40 116 -109 135 -257 l6 -48 -292 0 -292 0 7 53 c18 155 94 252 216 278 64 14 164 2 220 -26z"/>
      <path d="M9238 6406 c-77 -22 -135 -55 -174 -98 -36 -40 -76 -117 -88 -170 l-6 -28 89 0 89 0 7 31 c12 52 70 107 133 125 73 22 192 14 249 -15 64 -32 85 -76 91 -185 l5 -93 -194 -6 c-249 -8 -334 -29 -423 -108 -87 -76 -122 -249 -77 -371 26 -66 82 -127 146 -157 148 -70 383 -42 498 60 l46 40 3 -58 3 -58 85 0 85 0 3 375 c1 226 -2 402 -8 443 -20 129 -86 214 -199 259 -71 27 -288 35 -363 14z m392 -659 c0 -50 -6 -102 -16 -130 -19 -57 -75 -116 -140 -148 -43 -21 -63 -24 -161 -24 -97 0 -115 3 -139 21 -56 41 -69 70 -69 147 0 89 27 143 89 178 57 32 89 37 274 38 l162 1 0 -83z"/>
      <path d="M12423 6405 c-63 -19 -124 -58 -171 -109 l-42 -46 0 75 0 75 -90 0 -90 0 0 -755 0 -756 88 3 87 3 3 274 2 273 50 -46 c58 -54 115 -81 207 -97 168 -29 343 28 436 144 91 111 121 218 121 427 0 140 -3 163 -26 232 -72 217 -216 319 -448 317 -47 0 -104 -6 -127 -14z m215 -150 c133 -40 202 -172 202 -390 0 -278 -105 -417 -315 -419 -68 -1 -88 3 -137 27 -67 33 -111 80 -147 157 -25 52 -26 63 -26 220 0 161 1 167 29 228 34 74 114 153 178 175 57 20 152 21 216 2z"/>
      <path d="M13621 6405 c-78 -22 -149 -64 -186 -110 -40 -50 -49 -67 -68 -132 l-16 -53 88 0 88 0 17 45 c40 104 167 148 325 114 100 -21 140 -83 141 -215 l0 -81 -182 -6 c-210 -6 -300 -22 -379 -70 -102 -62 -157 -183 -146 -320 7 -84 35 -143 91 -195 136 -125 429 -119 574 13 l41 36 3 -58 3 -58 85 0 85 0 0 425 c0 419 0 426 -23 481 -29 73 -98 138 -178 169 -77 30 -279 38 -363 15z m389 -668 c0 -80 -4 -100 -26 -145 -51 -102 -147 -152 -290 -152 -93 0 -148 19 -179 62 -41 60 -42 170 -2 229 54 80 115 97 335 98 l162 1 0 -93z"/>
      <path d="M16720 6402 c-81 -27 -117 -48 -166 -98 l-44 -43 0 69 0 70 -90 0 -90 0 2 -542 3 -543 87 -3 86 -3 4 363 c4 355 4 364 27 415 27 60 93 126 154 154 31 13 65 19 128 19 118 0 162 -23 204 -105 l30 -60 5 -390 5 -390 88 -3 88 -3 -3 433 c-4 491 -4 488 -84 577 -65 73 -122 94 -264 98 -91 2 -126 -1 -170 -15z"/>
      <path d="M17911 6404 c-159 -43 -268 -161 -318 -344 -25 -91 -25 -338 1 -429 33 -121 96 -216 178 -267 166 -105 476 -92 610 26 49 42 102 134 113 193 l7 37 -84 0 -84 0 -13 -35 c-32 -89 -104 -135 -226 -143 -116 -7 -186 15 -246 77 -53 55 -76 113 -86 213 l-6 68 379 2 379 3 -1 125 c-1 133 -15 203 -59 290 -25 49 -103 128 -151 153 -89 46 -282 61 -393 31z m228 -135 c104 -22 170 -102 191 -230 5 -35 10 -72 10 -81 0 -17 -20 -18 -290 -18 l-290 0 0 28 c0 45 29 144 56 190 56 95 188 140 323 111z"/>
      <path d="M14955 6396 c-65 -21 -104 -45 -151 -93 l-44 -47 0 35 c0 19 -3 51 -6 72 l-7 37 -83 0 -84 0 0 -545 0 -546 88 3 87 3 5 360 c6 397 5 392 73 470 19 22 59 54 88 70 49 27 63 29 146 30 l93 0 0 83 0 82 -82 -1 c-46 0 -101 -6 -123 -13z"/>
      <path d="M19235 6396 c-66 -21 -98 -42 -148 -95 l-47 -50 0 74 0 75 -90 0 -90 0 0 -545 0 -546 88 3 87 3 5 355 c4 311 7 360 23 394 28 62 80 119 138 151 49 27 63 29 146 30 l93 0 0 83 0 82 -82 -1 c-46 0 -101 -6 -123 -13z"/>
      <path d="M90 3714 l0 -776 363 4 c345 4 365 5 432 27 220 72 343 208 406 446 27 102 37 372 20 497 -38 260 -164 449 -350 523 -120 48 -185 55 -542 55 l-329 0 0 -776z m695 596 c135 -34 211 -96 266 -215 48 -101 62 -189 62 -375 0 -312 -65 -475 -223 -559 -92 -49 -167 -61 -397 -61 l-203 0 0 615 0 615 208 0 c176 0 221 -3 287 -20z"/>
      <path d="M7370 3715 l0 -775 90 0 89 0 3 373 3 372 27 46 c71 120 204 178 344 149 45 -10 67 -21 95 -49 65 -65 69 -94 69 -518 l0 -373 90 0 91 0 -3 433 -3 432 -33 67 c-58 119 -154 170 -322 171 -147 0 -267 -45 -335 -125 l-25 -30 0 301 0 301 -90 0 -90 0 0 -775z"/>
      <path d="M10440 3715 l0 -775 350 0 c395 0 453 7 576 66 165 78 252 256 224 457 -22 154 -107 253 -257 297 l-42 13 42 21 c23 11 63 41 89 67 86 86 112 250 61 392 -24 68 -100 149 -169 182 -103 49 -160 55 -531 55 l-343 0 0 -775z m665 614 c145 -27 208 -99 208 -239 0 -117 -49 -191 -152 -232 -51 -20 -78 -22 -298 -26 l-243 -4 0 256 0 256 213 0 c117 0 240 -5 272 -11z m-17 -659 c162 -15 238 -55 283 -147 19 -38 24 -65 24 -128 0 -98 -16 -146 -67 -201 -78 -85 -167 -104 -485 -104 l-223 0 0 295 0 295 188 0 c103 0 229 -5 280 -10z"/>
      <path d="M14460 3715 l0 -775 90 0 90 0 2 266 3 266 243 -266 244 -266 116 0 115 0 -20 23 c-11 12 -130 139 -263 282 -133 143 -244 266 -247 272 -3 7 101 122 231 255 l236 243 -102 3 -103 3 -90 -93 c-50 -51 -152 -158 -228 -238 l-137 -145 0 473 0 472 -90 0 -90 0 0 -775z"/>
      <path d="M4290 4170 l0 -150 -100 0 -100 0 0 -70 0 -70 99 0 100 0 3 -382 c3 -290 7 -392 17 -418 22 -56 61 -101 105 -121 54 -24 204 -40 281 -31 l60 7 3 71 3 72 -104 -1 c-82 -1 -110 2 -130 16 -51 33 -52 37 -55 425 l-3 362 140 0 141 0 0 70 0 70 -140 0 -140 0 0 150 0 150 -90 0 -90 0 0 -150z"/>
      <path d="M2045 4040 c-184 -25 -312 -133 -372 -314 -22 -68 -27 -105 -31 -223 -5 -156 10 -251 53 -350 26 -58 92 -134 146 -168 123 -78 347 -93 507 -33 84 31 172 122 200 207 12 36 22 69 22 73 0 4 -38 8 -84 8 l-84 0 -28 -57 c-26 -51 -36 -62 -89 -88 -53 -27 -70 -30 -150 -30 -108 0 -154 15 -211 67 -55 51 -85 122 -92 220 l-5 78 376 0 376 0 7 35 c15 80 -14 268 -55 361 -24 53 -92 128 -145 159 -82 47 -224 70 -341 55z m179 -154 c103 -31 165 -118 174 -246 l5 -70 -287 0 -286 0 0 34 c0 65 38 162 83 211 69 76 196 105 311 71z"/>
      <path d="M5340 4039 c-212 -28 -335 -158 -317 -334 11 -112 76 -198 177 -236 30 -12 121 -34 202 -49 235 -46 273 -72 273 -185 0 -78 -24 -118 -89 -150 -43 -21 -64 -25 -145 -25 -53 0 -113 5 -134 11 -57 15 -115 73 -128 125 l-11 44 -89 0 -88 0 5 -32 c27 -155 111 -240 274 -278 73 -17 241 -18 310 -1 123 30 211 95 251 183 35 79 34 193 -4 270 -52 106 -122 144 -348 188 -175 35 -220 51 -255 93 -31 37 -33 116 -4 164 30 50 72 66 181 71 103 5 150 -5 194 -40 29 -23 65 -87 65 -118 0 -19 6 -20 91 -20 l91 0 -7 43 c-16 93 -64 171 -134 216 -81 52 -233 77 -361 60z"/>
      <path d="M6535 4040 c-184 -25 -309 -131 -371 -315 -35 -106 -44 -288 -20 -415 53 -281 257 -427 546 -391 47 6 107 22 141 37 107 48 188 163 206 290 l6 44 -91 0 -90 0 -6 -44 c-10 -66 -55 -129 -112 -157 -61 -29 -186 -32 -249 -5 -120 51 -175 177 -175 401 0 175 36 286 112 350 51 43 96 58 178 58 81 0 137 -17 179 -56 28 -26 71 -113 71 -144 0 -22 3 -23 90 -23 103 0 102 -1 76 95 -35 133 -123 222 -256 258 -73 20 -162 26 -235 17z"/>
      <path d="M9015 4040 c-183 -25 -307 -128 -372 -311 -23 -64 -27 -94 -31 -224 -4 -167 9 -256 52 -350 27 -59 100 -144 150 -174 121 -74 348 -87 502 -30 102 38 191 145 218 262 l6 27 -83 0 -84 0 -29 -57 c-25 -50 -37 -62 -89 -88 -52 -27 -68 -30 -149 -30 -107 0 -154 14 -208 63 -62 56 -90 124 -102 250 l-5 52 379 0 379 0 6 44 c16 102 -24 309 -75 392 -33 53 -116 121 -175 143 -44 16 -204 45 -225 40 -3 -1 -32 -5 -65 -9z m179 -154 c103 -31 162 -115 173 -245 l6 -71 -288 0 -288 0 7 61 c13 133 91 233 201 260 51 12 139 10 189 -5z"/>
      <path d="M12275 4040 c-176 -24 -292 -124 -329 -282 l-6 -28 89 0 c89 0 89 0 96 28 23 91 88 133 215 140 105 5 164 -10 206 -52 39 -39 54 -89 54 -182 l0 -71 -197 -6 c-196 -5 -248 -13 -335 -50 -56 -24 -123 -91 -149 -150 -31 -72 -38 -196 -14 -268 23 -67 89 -140 154 -168 159 -68 383 -37 504 69 l37 32 0 -56 0 -56 91 0 90 0 -3 418 c-4 376 -6 422 -23 470 -35 101 -125 175 -240 200 -74 16 -172 21 -240 12z m323 -687 c-5 -110 -22 -155 -80 -209 -61 -58 -113 -77 -218 -82 -84 -3 -100 -1 -139 20 -59 30 -81 63 -88 129 -7 73 10 132 52 174 54 53 103 64 304 64 l173 1 -4 -97z"/>
      <path d="M13620 4039 c-86 -11 -198 -66 -244 -121 l-36 -42 0 72 0 72 -90 0 -90 0 0 -540 0 -540 95 0 94 0 3 373 c3 360 4 373 25 413 62 117 210 183 344 155 68 -15 116 -52 143 -110 20 -44 21 -62 24 -438 l3 -393 90 0 89 0 0 408 c0 440 -3 469 -54 557 -62 104 -218 157 -396 134z"/>
      <path d="M2920 3606 c0 -384 1 -419 20 -478 36 -118 123 -186 265 -208 151 -24 327 31 400 124 l25 31 0 -67 0 -68 90 0 90 0 0 540 0 540 -89 0 -90 0 -3 -367 c-3 -361 -3 -369 -26 -415 -26 -53 -82 -107 -144 -140 -35 -18 -59 -22 -128 -22 -109 -1 -155 22 -197 98 l-28 51 -3 398 -3 397 -90 0 -89 0 0 -414z"/>
    </g>
  </svg>
);

export default SvgDeutscheBank;
