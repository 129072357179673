import { Divider } from "components/Divider/Divider";
import React from "react";
import DontMiss from "../DontMiss";
import News from "../News/News";
import shared from "../shared-styles.module.scss";
import classes from "./PublicNews.module.scss";
const PublicNews = () => {
  return (
    <div className={classes.root}>
      <DontMiss className={classes.dontMiss} />
      <Divider className={shared.divider} />
      <News className={classes.news} />
    </div>
  );
};

export default PublicNews;
