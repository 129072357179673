import { ButtonOld } from "components/Button/ButtonOld";
import { MobileFriezeIcon } from "components/icons";
import { androidAppLink, iosAppLink } from "config";
import MobileBanner from "features/Banner/MobileBanner/MobileBanner";
import React, { useMemo } from "react";
import isIOS from "utils/isIos";
import classes from "./PlanYourVisitBannerMobile.module.scss";

type Props = {
  hideHeader?: boolean;
};

/**
 * @deprecated since version 3.0
 */
const PlanYourVisitBannerMobile = ({ hideHeader }: Props) => {
  const ios = useMemo(() => isIOS(), []);

  return (
    <MobileBanner hideHeader={hideHeader}>
      <MobileFriezeIcon className={classes.logo} width="126" height="126" />
      <p className={classes.text}>
        Download the app for iOS or Android <br />
        now to enter Frieze Viewing Room
      </p>
      <ButtonOld
        classes={{
          root: classes.button,
          text: classes.buttonText,
          content: classes.buttonContent,
        }}
        className={classes.findOutMore}
        color="primary"
        nativeLink
        nativeLinkProps={{
          target: "_blank",
          rel: "nofollow noopener",
        }}
        to={ios ? iosAppLink : androidAppLink}
      >
        Download the App
      </ButtonOld>
    </MobileBanner>
  );
};

export default PlanYourVisitBannerMobile;
