import { styled } from "@linaria/react";
import clsx from "clsx";
import Button from "components/Button/Button";
import React from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { customProps } from "styles";
import useFiltersMenu, { FilterePanelKeys } from "../hooks/useFiltersMenu";
import SearchBaseMenu from "../SearchBaseMenu/SearchBaseMenu";
import {
  FilterCheckbox,
  FilterCheckboxLabel,
} from "./components/FilterCheckbox";

export interface SearchFiltersProps extends React.HTMLAttributes<HTMLElement> {
  name: FilterePanelKeys;
  variant?: "shallow-search" | "search-page";
}

const Buttons = styled.div`
  display: flex;
  padding-top: 30px;
`;

const FilterOptions = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  &.shallow-search {
    max-height: calc(
      (var(--vh, 1vh) * 100) - var(${customProps.headerHeight}) -
        var(${customProps.subHeaderHeight}) - var(${customProps.buttonsHeight})
    );
  }

  &.search-page {
    max-height: calc(
      (var(--vh, 1vh) * 100) - var(${customProps.headerHeight}) -
        var(${customProps.subHeaderHeight}) - 264px
    );
  }
`;

const StyledButton = styled(Button)`
  font-size: 12px;
  min-height: 35px;
  letter-spacing: 0.67px;
  cursor: pointer;

  .dark & {
    border: 1px solid white;
    background-color: transparent;
  }

  & + & {
    margin-left: 15px;
  }
`;

const StyledCheckboxLabel = styled(FilterCheckboxLabel)`
  font-size: 14px;
  & + & {
    margin-top: 19px;
  }
`;

const SearchFiltersMenu = React.forwardRef<HTMLDivElement, SearchFiltersProps>(
  ({ className, name, variant = "shallow-search", ...rest }, ref) => {
    const { change } = useForm();
    const { dirty } = useFormState();
    const { filtersMenuItems } = useFiltersMenu();

    return (
      <SearchBaseMenu className={className} ref={ref} {...rest}>
        <FilterOptions className={variant}>
          {filtersMenuItems[name]?.map((item) => (
            <StyledCheckboxLabel key={item.key}>
              <Field
                type={item.type}
                name={name}
                value={item.key}
                component={FilterCheckbox}
                variant="filled"
              />
              {item.label}
            </StyledCheckboxLabel>
          ))}
        </FilterOptions>
        <Buttons>
          <StyledButton
            className={clsx("uppercase", "fullWidth", "small")}
            onClick={() => change(name, [])}
            type="button"
          >
            Clear All
          </StyledButton>
          <StyledButton
            className={clsx("uppercase", "fullWidth", "small", {
              pressed: dirty,
            })}
            type="submit"
          >
            Apply
          </StyledButton>
        </Buttons>
      </SearchBaseMenu>
    );
  }
);

export default SearchFiltersMenu;
