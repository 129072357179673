import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import FriezeLogoNext from "components/icons/FriezeLogoNext";
import Svg from "components/Svg";
import usePreviewSession from "features/Auth/hooks/usePreviewSession";
import { homeRouteConfig } from "features/Home/home.routes";
import useMobileMenu from "features/MobileMenu/hooks/useMobileMenu";
import MobileNavigationMenu from "features/MobileMenu/MobileNavigationMenu";
import MobileMainNavigation from "features/Navigation/MobileMainNavigation";
import React, { PropsWithChildren, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import MenuAction from "./components/MenuAction";
import SearchAction from "./components/SearchActionMobile";
import SearchOverlayMobile from "./components/SearchOverlayMobile";

const HeaderWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 104;

  background-color: #ffffff;
`;

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: 8px;
  padding-right: 10px;

  border-top: 1px solid black;
  border-bottom: 1px solid black;

  height: 52px;
  height: var(--header-height, 52px);
`;

const HeaderPlaceholder = styled.div`
  height: 52px;
  height: var(--header-height, 52px);
`;

const logo = css`
  font-size: 10pt;

  & svg {
    max-width: 100%;
  }
`;

const Spacer = styled.div`
  height: 100%;
  min-width: 50px;

  &:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

type MobileHeaderProps = PropsWithChildren<{
  className?: string;
  Navigation?: React.ComponentType;
  RightAction?: React.ComponentType<any>;
  LeftAction?: React.ComponentType<any>;
}>;

const MobileHeader = ({
  className,
  Navigation = MobileMainNavigation,
  RightAction = SearchAction,
  LeftAction = MenuAction,
}: MobileHeaderProps) => {
  const { opened, close } = useMobileMenu();

  const { pathname } = useLocation();
  useEffect(() => {
    close();
  }, [close, pathname]);

  const { isPreview } = usePreviewSession();

  return (
    <>
      <HeaderWrapper className={className}>
        <HeaderContainer id="header-navigation">
          <Spacer>{!isPreview && <LeftAction />}</Spacer>
          <Link to={homeRouteConfig.link}>
            <Svg className={logo}>
              <FriezeLogoNext height="19" />
            </Svg>
          </Link>
          <Spacer>{!isPreview && <RightAction />}</Spacer>
          <MobileNavigationMenu isOpen={opened} onRequestClose={close}>
            <Navigation />
          </MobileNavigationMenu>
        </HeaderContainer>
        <SearchOverlayMobile />
      </HeaderWrapper>
      <HeaderPlaceholder className="placeholder" />
    </>
  );
};

export default MobileHeader;
