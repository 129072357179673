const monthNames = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

export default function formatDate(input: string) {
  if (!input) {
    return "";
  }
  const timestamp = parseInt(input);
  if (isNaN(timestamp)) {
    return "";
  }
  const date = new Date(timestamp * 1000);
  return `${date.getDate().toString().padStart(2, "0")} ${
    monthNames[date.getMonth()]
  } ${date.getFullYear()}`;
}
