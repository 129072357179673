import { css } from "@linaria/core";
import clsx from "clsx";
import React from "react";

export const headerItemTextCss = css`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  font-size: 13px;
  letter-spacing: 0.6px;
  line-height: 20px;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  color: #000000;

  transition: opacity 100ms ease-in-out;

  & {
    text-decoration: none;
  }

  &::after {
    content: attr(data-text);
    content: attr(data-text) / "";
    height: 0;
    visibility: hidden;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    font-weight: 500;

    @media speech {
      display: none;
    }
  }
`;

const navigationItemText = css`
  .active > &,
  &.active {
    font-weight: 500;
    opacity: 1;
  }
  


  .expanded .main:not(.active) > &,
  &.inline:not(.active) {
    opacity: 0.75;
    font-weight: 500;
  }

  & {
    opacity: 0.75;
  }

  &:hover {
    opacity: 1;
  }
`;

export const navigationItemTextCss = clsx(
  navigationItemText,
  headerItemTextCss
);

export default function NavigationItemText({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  return <div className={clsx(className, navigationItemTextCss, 'nav-item')} {...props} />;
}
