import { styled } from "@linaria/react";
import {primaryFont, secondaryFont} from "styles";
import { up } from "styles/utils/breakpoints";
import em from "styles/utils/em";
import fluid from "styles/utils/fluid";

export const NewsSectionTitle = styled.h2`
  text-transform: uppercase;
  ${fluid("font-size", {
    base: "24px",
    xs: "28px",
  })};
  line-height: 1.2;
  letter-spacing: ${em("0.46px", "20px")};

  ${up("lg")} {
    ${fluid("font-size", {
      lg: "20px",
      xlg: "21px",
      xl: "28px",
    })};
    text-align: center;
  }
`;

export const DontMissTitle = styled.p`
  ${fluid("font-size", {
    base: "13px",
    xs: "15px",
  })};
  overflow-wrap: anywhere;
  line-height: 1.2;
  font-weight: normal;
  letter-spacing: ${em("0.48px", "15px")};
  text-decoration: none;
  text-transform: uppercase;

  ${up("lg")} {
    ${fluid("font-size", {
      lg: "11px",
      xlg: "11px",
      xl: "14px",
    })};
    line-height: ${em("11.1px", "10.38px")};
    letter-spacing: ${em("0.43px", "11px")};
  }
`;

export const NewsTitle = styled.p`
  ${fluid("font-size", {
    base: "19px",
    xs: "19px",
  })};
  line-height: ${em("27px", "21px")};
  letter-spacing: ${em("0.37px", "21px")};
  overflow-wrap: anywhere;
  font-family: ${primaryFont};
  font-weight: 400;
  text-align: center;

  ${up("lg")} {
    ${fluid("font-size", {
      lg: "16px",
      xlg: "19px",
      xl: "19px",
    })};
    line-height: 1.2;
    letter-spacing: ${em("0.29px", "21px")};
  }
`;

export const NewsDateText = styled.div`
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: 0.46px;
  text-transform: uppercase;

  opacity: 0.55;

  ${up("lg")} {
    opacity: 1;
    ${fluid("font-size", {
      lg: "8px",
      xlg: "11px",
      xl: "11px",
    })}
  }
`;
