export const searchDebounceDelay = 300;
export const checkShowExhibitionDate = false;
export const selfOrigin = 'https://viewingroom.frieze.com';

export type BackgroundConfig = {
  width: number;
  height: number;
  rationPxCm: number;
  wallHeightPx: number;
  backgroundPositionX: number | null;
  topPadding: number;
  backgroundImage: string;
};

const backgroundConfigBase = {
  width: 5699,
  height: 3000
};

const baseBackgroundConfigNear = {
  ...backgroundConfigBase,
  wallHeightPx: 2070,
  topPadding: 0
};

export const backgroundConfigFar: Omit<BackgroundConfig, 'backgroundImage'> = {
  ...backgroundConfigBase,
  wallHeightPx: 2700,
  topPadding: -100,
  backgroundPositionX: -170,
  rationPxCm: 6
};

export const backgroundConfigNear: Omit<BackgroundConfig, 'backgroundImage'> = {
  ...baseBackgroundConfigNear,
  rationPxCm: 9.4,
  wallHeightPx: 2700,
  topPadding: -100,
  backgroundPositionX: -270
};

export const wsAnalyticsHost =
  process.env.NODE_ENV !== 'production'
    ? 'dev.viewingroom.frieze.com'
    : window.location.host;

export const paginationLimit = 20;
export const jwPlayerScript = 'https://cdn.jwplayer.com/libraries/yKWvOSbH.js';

// Dev - 2
// Staging - 2
// Production - 10
export const FMFairId = 2;

export const FLFairId = 1;

export const WedVipAccessId = 1;
export const ThurVipAccessId = 2;
export const PublicUserAccessId = 3;

export const editAccountInfoLink = 'https://www.frieze.com/account/edit';
export const vipProgrammingLink =
  'https://www.frieze.com/fairs/frieze-los-angeles/program';

export const wedVipProgrammeLink =
  'https://www.frieze.com/fairs/frieze-viewing-room/programme/vip-programme?location=in-london';

export const friezeWeekLink = 'https://www.frieze.com/frieze-week';

export const pdfViewrLink = '/pdf-viewer.html';
export const welcomeFriezeLink =
  'https://www.frieze.com/article/welcome-FVR-victoria-siddall-and-claudio-de-sanctis';

export const partnersSectionId = 10;
export const iosAppLink =
  'https://apps.apple.com/ru/app/frieze-viewing-room/id1500340570';
export const androidAppLink =
  'https://play.google.com/store/apps/details?id=com.frieze.viewingroom';

export const getAppBannerPath = '/a2e84063-a49c-4e3b-9fc7-8a09918213ef';

export const partnersLink = '/';

export const dbLink = 'https://www.deutsche-bank.de';

export const VorticGalleries = [
    {
      id: '1484', vorticLink: `https://embed-v4.vortic.io/#e=4554&bannerOnly=true&collapsed=true`,
      artworks: new Map<string, string>([
        [ '20825', '9556' ],
        [ '21596', '9559' ],
        [ '21594', '9558' ],
        [ '21569', '9550' ],
        [ '21597', '9554' ],
        [ '20846', '9560' ],
        [ '21545', '9555' ],
        [ '20909', '9557' ],
        [ '21579', '9564' ],
        [ '20853', '9563' ],
        [ '20839', '9561' ],
        [ '20911', '9622' ],
        [ '21599', '9624' ],
        [ '20825', '9625' ],
        [ '21584', '9552' ]
      ])
    },
    {
      id: '1489', vorticLink: `https://embed-v4.vortic.io/#e=4526&bannerOnly=true&collapsed=true`,
      artworks: new Map<string, string>([
        [ '20971', '9455' ],
        [ '21589', '9528' ],
        [ '20986', '9527' ],
        [ '21267', '9459' ],
        [ '21536', '9524' ],
        [ '21557', '9526' ],
        [ '21560', '9529' ],
        [ '21563', '9530' ],
        [ '21567', '9531' ],
        [ '20988', '9456' ],
        [ '20977', '9457' ],
        [ '20989', '9537' ],
        [ '20970', '9538' ],
        [ '20966', '9458' ],
        [ '20975', '9518' ]

      ])
    },
    {
      id: '1483', vorticLink: `https://embed-v4.vortic.io/#e=4544&bannerOnly=true&collapsed=true`,
      artworks: new Map<string, string>([
        [ '21714', '8927' ],
        [ '21587', '8926' ],
        [ '21211', '8847' ],
        [ '21212', '8848' ],
        [ '21279', '9010' ],
        [ '21320', '8851' ],
        [ '21215', '9394' ],
        [ '21216', '8932' ],
        [ '21217', '8935' ],
        [ '21219', '8937' ],
        [ '21220', '8938' ],
        [ '21577', '9271' ],
        [ '21418', '8928' ],
        [ '21792', '9010' ],
        [ '21786', '9042' ],
        [ '21796', '9584' ]
      ])
    },
    {
      id: '1440', vorticLink: `https://embed-v4.vortic.io/#e=4535&bannerOnly=true&collapsed=true`, artworks: new Map<string, string>([
        [ '21306', '9479' ],
        [ '21296', '9475' ],
        [ '21292', '9473' ],
        [ '21305', '9476' ],
        [ '21308', '9480' ],
        [ '21295', '9478' ],
        [ '21300', '9474' ],
        [ '21308', '9597' ],
        [ '21803', '9630' ]
      ])
    }
    ,
    {
      id: '1428', vorticLink: `https://embed-v4.vortic.io/#e=4556&bannerOnly=true&collapsed=true`, artworks: new Map<string, string>([
        [ '20996', '9499' ],
        [ '20995', '9494' ],
        [ '20990', '9496' ],
        [ '20991', '9504' ],
        [ '20998', '9503' ],
        [ '20993', '9505' ],
        [ '21002', '9506' ],
        [ '20992', '9507' ],
        [ '21006', '9508' ],
        [ '20999', '9509' ],
        [ '20997', '9567' ],
        [ '20994', '9634' ],
        [ '21802', '9569' ],
        [ '21797', '9568' ],
        [ '21801', '9571' ],
        [ '21799', '9572' ],
        [ '21800', '9573' ],
        [ '21798', '9570' ]

      ])
    }
    ,
    {
      id: '1469', vorticLink: `https://embed-v4.vortic.io/#e=4560&bannerOnly=true&collapsed=true`,
      artworks: new Map<string, string>([
        [ '21669', '9589' ],
        [ '21671', '9585' ],
        [ '21717', '9610' ],
        [ '21710', '9586' ],
        [ '21670', '9588' ],
        [ '21718', '9611' ],
        [ '21672', '9590' ],
        [ '21673', '9591' ],
        [ '21719', '9612' ]
      ])
    }
    ,
    {
      id: '1424', vorticLink: `https://embed-v4.vortic.io/#e=1424&bannerOnly=true&collapsed=true`, artworks: new Map<string, string>([
        [ '21152', '9392' ],
        [ '21411', '9387' ],
        [ '21595', '9393' ],
        [ '21598', '9386' ],
        [ '21288', '9534' ],
        [ '21412', '9388' ],
        [ '21480', '9389' ],
        [ '21175', '9636' ]
      ])
    }
    ,
    {
      id: '1435', vorticLink: ``, artworks: new Map<string, string>([
        [ '21615', '21615' ],
        [ '21615', '21615' ],
        [ '21615', '21615' ],
        [ '21615', '21615' ],
        [ '21615', '21615' ],
        [ '21615', '21615' ],
        [ '21615', '21615' ],
        [ '21615', '21615' ],
        [ '21615', '21615' ],
        [ '21615', '21615' ],
        [ '21615', '21615' ],
        [ '21615', '21615' ],
        [ '21615', '21615' ],
        [ '21615', '21615' ]
      ])
    }
    ,
    {
      id: '1452', vorticLink: `https://embed-v4.vortic.io/#e=4574&bannerOnly=true&collapsed=true`,
      artworks: new Map<string, string>([
        [ '21615', '9465' ],
        [ '21617', '9512' ],
        [ '21727', '9612' ],
        [ '21726', '9620' ],
        [ '21721', '9628' ],
        [ '21722', '9639' ],
        [ '21616', '9467' ],
        [ '21724', '9614' ]
      ])
    }
    ,
    {
      id: '1339', vorticLink: `https://embed-v4.vortic.io/#e=4383&bannerOnly=true&collapsed=true`,
      artworks: new Map<string, string>([
        [ '20825', '9556' ],
        [ '21596', '9559' ],
        [ '21594', '9558' ],
        [ '21569', '9550' ],
        [ '21597', '9554' ],
        [ '20846', '9560' ],
        [ '21545', '9555' ],
        [ '20909', '9557' ],
        [ '21579', '9564' ],
        [ '20853', '9563' ],
        [ '20839', '9561' ],
        [ '20911', '9622' ],
        [ '21599', '9624' ],
        [ '20825', '9625' ]
      ])
    }
  ]
;
