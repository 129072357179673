import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";
import { down, up } from "styles/utils/breakpoints";
import fluid from "styles/utils/fluid";
import {
  NewsDateText,
  NewsSectionTitle,
  NewsTitle
} from "../components/NewsTypography";
import useHomePageNews from "../hooks/useHomePageNews";
import formatDate from "../utils/formatDate";

type NewsProps = {
  className?: string;
};

const ListItem = styled.li`
  border-bottom: 1px solid black;

  &:first-child {
    border-top: 1px solid black;
  }

  ${up('lg')} {
    padding-top: 15px;
    padding-bottom: 12px;
  }

  ${up('xlg')} {
    padding-top: 18px;
    padding-bottom: 16px;
  }

  ${up('xl')} {
    padding-top: 25px;
    padding-bottom: 20px;
  }
`;

const linkCss = css`
  margin-top: 5px;
`;

const Root = styled.div``;

const SectionTitle = styled(NewsSectionTitle)`
  font-size: 19px !important;
  ${down("lg")} {
    margin-bottom: 28px;
  }
`;

const Date = styled(NewsDateText)`
  ${fluid("margin-top", {
    lg: "5px",
    xlg: "11px",
    xl: "15px",
  })}

  text-align: center;
`;

const List = styled.ul`
  margin-top: 18px;

  ${up("lg")} {
    ${fluid("margin-top", {
      lg: "8px",
      xlg: "12px",
      xl: "15px",
    })}
  }
`;

const News = ({ className }: NewsProps) => {
  const { data } = useHomePageNews();
  return (
    <Root className={className}>
      <SectionTitle>News</SectionTitle>
      <List>
        {data?.map((news, index) => (
          <ListItem key={index}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={news.url}
              className={linkCss}
            >
              <NewsTitle as="h3">{news.title}</NewsTitle>
              <Date as="p">{formatDate(news.created)}</Date>
            </a>
          </ListItem>
        ))}
      </List>
    </Root>
  );
};

export default News;
