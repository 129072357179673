import { api } from "index";
import { useQuery } from "react-query";
import { NewsItem } from "../interfaces/news.intefaces";

export const NEWS_CACHE_KEY = "news";
export const NEWS_HOME_CACHE_KEY = "news-home";

const fetchNewsRequest = () => {
  return api.get(`/api/v1/public/frieze-news`).json<Array<NewsItem>>();
};

export default function useHomePageNews() {
  return useQuery([NEWS_HOME_CACHE_KEY], fetchNewsRequest, {
    staleTime: Infinity,
  });
}
