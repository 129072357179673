import { styled } from "@linaria/react";

const NestedNavList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "FriezeSans";
  font-size: 13px;
  text-transform: uppercase;
  height: 100%;
`;

export default NestedNavList;
