import { styled } from "@linaria/react";

export const RatioContent = styled.div``;
export const RATIO_CHILD = "ratio-child";

const RatioWrapperBase = styled.div`
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%;
  }
  & ${RatioContent}, & > img,
  & .${RATIO_CHILD}, & > picture > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  & > img,
  & > picture > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ratio = (ratio: number) => {
  return `&:before {
    padding-top: ${(1 / ratio) * 100}%;
  }`;
};

export default RatioWrapperBase;
