import { styled } from "@linaria/react";
import clsx from "clsx";
import Footer from "components/Footer/Footer";
import RootThemedLayout from "components/RootThemedLayout";
import HeaderSwitch from "features/Header/HeaderSwitch";
import useConstants from "features/ServerConstants/hooks/useConstants";
import React, { useEffect, useMemo } from "react";
import { down } from "styles/utils/breakpoints";
import FaqContextNavigation from "../FaqContext/FaqContextNavigation";

let isIntercomLoaded = false;

interface FAQProps {
  Header?: React.ComponentType<any>;
}

const Title = styled.div`
  position: sticky;
  font-family: 'FriezeSans';
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #000;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.65px;
  text-transform: uppercase;
  text-align: center;

  ${down('md') }{
    height: 58px;
  }

  &.NotFirst {
    border-top: 1px solid #000;
  }
`;


const Body = styled.div`
  font-family: SinaNova;
  max-width: 530px;
  margin: 0 auto;
  font-size: 15px;
  padding: 0 20px 20px;
  margin-top: 56px;
  line-height: 19px;
  letter-spacing: 0.25px;

  color: #000;
  ${down('md') }{
    margin-top: 20px;
  } 
  .group {
    margin-bottom: 20px;
  }

  ul {

    > .group > li {
      list-style: none;
      text-decoration: underline;
    }
  }

  section {
    margin-bottom: 25px;
    .bold {
      font-weight: bold;
    }
    a {
      color: blue;
      text-decoration: underline;
    }
  }
  section:last-of-type {
    margin: 0;
  }
`;

const FAQ = ({ Header = HeaderSwitch }: FAQProps) => {
  const { data: constants } = useConstants()

  const faqContent = useMemo(() => {
    return constants?.FaqContent || []
  }, [constants])

  useEffect(() => {
    if (!isIntercomLoaded) {
      (window as any).Intercom("update", {
        hide_default_launcher: false,
      });
      isIntercomLoaded = true;
    }

    return () => {
      (window as any).Intercom("update", {
        hide_default_launcher: true,
      });
      isIntercomLoaded = false;
    };
  }, []);

  return (
    <RootThemedLayout>
      <Header />
      <FaqContextNavigation />

      {faqContent.map((item, index) => (
        <>
          <Title className={clsx({NotFirst: index !== 0})}>{ item.title }</Title>
          <Body dangerouslySetInnerHTML={{ __html: item.content }} />
        </>
      ))}
{/*       
      <Title>Frequently Asked Questions</Title>
      <Body>
        <section>

          <div className="group">
            <p className="bold">
            What time will Frieze Viewing Room be open to invited guests, 
            Frieze members and public audiences?   
            </p>
          </div>
          <div className="group">
            <p >
            Visitors will be able to access Frieze Viewing Room 
            at the following times:
            </p>
          </div>

          <ul>
            <div className="group">
              <li>Invitation only</li>
              <p>
                Tuesday, July 27, 8am PST
              </p>
            </div>
            <div className="group">
              <li>Invitation & Frieze members only</li>
              <p>
                Wednesday, July 28, 8am PST
              </p>
            </div>
            <div className="group">
              <li>Open access to all registered account holders</li>
              <p>
                Thursday, July 29, 8am PST
              </p>
            </div>

          </ul>
        </section>
        <section>
          
          <p className="bold">When Does Frieze Viewing Room Close?</p>
          <p>Frieze Viewing Room closes on Saturday, August 1, at 00:00 (midnight) PST</p>
        </section>
        <section>
          <p className="bold">How Are The Artworks and Galleries Organized?</p>
          <p>
            Each gallery has their own dedicated Viewing Room where artworks 
            are presented, alongside information on the works and artists. 
            Viewing Rooms are collected on the homepage for browsing. Viewing 
            Rooms, Artworks and Artists are also findable through Searching, Filtering 
            and Sorting using the Search icon from the top of any page.    
          </p>
        </section>
        <section>
          <p className="bold">Where Do I Find The Price Of The Artworks?</p>
          <p>
            The listing of prices is at the discretion of the gallery. 
            All artworks have a ‘price band’ in $USD so that they will 
            appear when using Price to filter lists of artworks. However, 
            the gallery also has the choice to either show the price band 
            on the artwork page, display a specific price in any currency 
            or to not display a price at all. 
          </p>
        </section>

        <section>
          <p className="bold">What Is The ‘Sign the Book’ Feature?</p>
          <p>
            The ‘Sign the Book’ feature informs a gallery 
            that you have visited their viewing room and shares 
            the email address with the gallery that you used to 
            register your account with Frieze, so that the gallery 
            can attempt to contact you.
          </p>
        </section>

        <section>
          <p className="bold">
            What Is The ‘Save’ Feature?
          </p>
          <p>
            The ‘save’ feature can be used for either Viewing Rooms or Artworks. 
            Saved Viewing Rooms and Artworks appear in your Account page, accessed 
            from the menu at the top of every screen, so that you can refer to these 
            lists to assist in navigating Frieze Viewing Room.
          </p>
        </section>
        <section>
          <p className="bold">What Does The ‘Visited’ Badge On Gallery Thumbnails Mean?</p>
          <p>
            Once you have been to the Viewing Room of a gallery, the thumbnail 
            for this gallery will have the ‘Visited’ badge appear on it so that 
            you can more easily follow which galleries you have and have not yet seen.
          </p>
        </section>
        <section>
          <p className="bold">How Do I Contact A Gallery Or Make An Inquiry About An Artwork?</p>
          <p>
            To make an inquiry about a specific artwork, navigate to the artwork’s page 
            and select the ‘Get In Touch’ button. The options available to contact the 
            gallery will then be presented. You can ‘Inquire’, to generate an email to 
            the gallery that will include your name and registered email so that the gallery 
            can reply to you directly. If the gallery is online, you will be able to contact them 
            via our Live Chat function on the gallery page. If the gallery has provided a phone 
            number by which they can be contacted, this will also be presented.
          </p>
        </section>
        <section>
          <p className="bold">Is Frieze Involved In Sales or Transactions?</p>
          <p>
            Frieze acts only to try to put collectors in touch with 
            galleries via the Get In Touch features. Such transactions 
            happen directly between the gallery and the collector once 
            they have been put in touch.
          </p>
        </section>
      </Body>
      <Title className="Nft">FAQs for Digital Art & NFTs</Title>
      <Body>
      <section>
          <p className="bold">What is an NFT?</p>
          <p>
            NFT is an acronym for Non-Fungible Token. This is a verifiably 
            unique digital asset, created (also known as ‘minted’) on a blockchain 
            and using the blockchain to prove authenticity and track ownership. 
            The NFTs for sale in the Digital Art section of Frieze Los Angeles 
            2021 Edition are minted to the ERC-721 standard on RNDR, a blockchain 
            GPU computing and 3D marketplace developed by pioneering Los Angeles-based 
            company OTOY. RNDR leverages Layer 2 technology to reduce carbon 
            emissions associated with the minting process.
          </p>
        </section>
        <section>
          <p className="bold">How Do I Purchase An NFT at Frieze Viewing Room?</p>
          <p>
          To purchase an NFT you will need to create an account with 
          OTOY, the technology provider for the NFTs.  If you wish 
          to make your purchase in crypto currency, you will also 
          need to have an ETH address. We recommend creating or 
          registering a MetaMask wallet. There is a choice of payment options.
          </p>
        </section>
        <section>
          <p className="bold">Browse NFTs For Sale</p>
          <p>
            All the NFTs for sale at Frieze Los Angeles 2021 
            Edition are presented in the same section: 
            Digital Art & NFTs - Curated by Venus Lau
          </p>
          <p>
            If you find an NFT you wish to purchase, 
            please tap “Purchase” from the artwork page
          </p>
        </section>
        <section>
          <p className="bold">Create or Sign In to an OTOY Account</p>
          <p>
            To complete your purchase you will need to be signed 
            into an OTOY account, in addition to your Frieze account
          </p>
          <p>
            The reason for this is that an OTOY account is required 
            in order to process your transaction and to access your 
            NFT after purchase has been completed
          </p>
          <p>
            You will need to create a MetaMask wallet in order to pay 
            with crypto (if preferred); create your metamask at <a href="https://metamask.io/">https://metamask.io/</a>
          </p>
        </section>
        <section>
          <p className="bold">Editions</p>
          <p>
            Some of the NFTs offered for sale are editioned; 
            you will need to select the specific edition you wish to purchase
          </p>
        </section>
        <section>
          <p className="bold">Payment Options</p>
          <p>
            Payment options include credit and 
            crypto payments and pricing is in $USD
          </p>
          <br/>
          <p>
            Paying by credit card:
          </p>
          <p>
            You can purchase your NFT using your credit card by 
            selecting this option from the payment options; payments are in $USD
          </p>
          <p>
            Most major credit cards are accepted; payments 
            are processed by leading international payments processor Stripe
          </p>
          <br/>
          <p>
          Paying by crypto:
          </p>
          <p>
            You can purchase your NFT using available crypto 
            funds in the MetaMask waller you have registered to your OTOY account
          </p>
          <p>Options for crypto currency payment include ETH, RNDR and USDC</p>
          <p>You will need to have an ETH wallet, such as MetaMask. Create your metamask at <a href="https://metamask.io">https://metamask.io</a></p>
        </section>
        <section>
          <p className="bold">Why Is Frieze Partnering With OTOY?</p>
          <p>
            Frieze Viewing Room utilises OTOY’s pioneering technologies to 
            mint the NFTs on the RNDR network where information about the 
            work will be registered in a blockchain database and verified 
            using RNDR’s authentication technology.
          </p>
        </section>
        <section>
          <p className="bold">Why Is There A 2-Week Waiting Period to Process Transactions</p>
          <p>
            Transactions will be pending for 2 weeks from the date of payment to ensure payment security and 
            fraud prevention; once processing is complete, the NFT will be transferred to and appear in the 
            MetaMask wallet registered to your OTOY account and you will be notified by email.
          </p>
        </section>
        <section>
          <p className="bold">Are Refunds Offered?</p>
          <p>Refunds are not be offered, there are no returns for NFTs purchased.</p>
        </section>
        <section>
          <p className="bold">What Are The Costs of Purchasing?</p>
          <p>
            The price quoted includes all costs, including credit 
            card fees if the credit card payment option is selected. 
            Frieze and OTOY take a total of 15% from the quoted sale 
            price to cover the costs associated with providing the 
            sales and transactional services.
          </p>
        </section>
      </Body> */}
      <Footer />
    </RootThemedLayout>
  );
};

export default FAQ;
