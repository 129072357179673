import useMobileMenu from "features/MobileMenu/hooks/useMobileMenu";
import useShallowSearch from "features/Search/hooks/useShallowSearch";
import React, { useCallback } from "react";
import { HeaderActionProps } from "../header.interfaces";
import SearchButton from "./SearchButton";
import { down } from "styles/utils/breakpoints";
import useBreakpoint from "styles/utils/useBreakpoint";

const SearchAction = ({ onClick, ...rest }: HeaderActionProps) => {
  const { searchOverlayState, closeSearch, showSearch } = useShallowSearch();
  
  const { close, opened } = useMobileMenu();
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (searchOverlayState.active) {
        closeSearch();
      } else {
        showSearch();
        close();
      }
      onClick && onClick(e);
    },
    [close, closeSearch, onClick, searchOverlayState.active, showSearch]
  );
  const downSm = useBreakpoint(down("sm"));
  const handleHover = useCallback(
    
    (e: React.MouseEvent) => {
      if(downSm) return
      if(opened) return
      showSearch();

      onClick && onClick(e);
    },
    [downSm, opened, showSearch, onClick]
  );


  return (
    <div>
      <SearchButton
        active={searchOverlayState.active}
        onClick={handleClick}
        {...rest}
      />
    </div>

  );
};

export default SearchAction;
