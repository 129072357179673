import { api } from "index";
import { useQuery } from "react-query";
import { DontMiss } from "../interfaces/dont-miss.intefaces";

const fetchDontMiss = () => {
  return api.get(`/api/v1/public/frieze-dont-miss`).json<DontMiss[]>();
};

const DONT_MISS_CACHE_KEY = "dont-miss";

export default function useDontMiss() {
  return useQuery([DONT_MISS_CACHE_KEY], fetchDontMiss, {
    staleTime: Infinity,
  });
}
