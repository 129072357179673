import { styled } from "@linaria/react";
import { colors, zIndex } from "styles";

const SearchBaseMenu = styled.div`
  position: relative;
  min-width: 273px;
  padding: 24px 17px 18px 20px;

  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.176027);
  background-color: #ffffff;
  z-index: ${zIndex.popover};

  .dark & {
    background-color: ${colors.dark};
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.34);
  }
`;
export default SearchBaseMenu;
